/* tslint:disable */
/* eslint-disable */
/**
 * Mjøsen Skog skogbruksleder API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { GmDtoLibraryDtoInvoiceReceiverDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoMessageDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoOrderDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoProductDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoProjectOrderDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoProjectOrderEmailDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoProjectOrderLineDto } from '../model';
// @ts-ignore
import { GmDtoLibraryDtoProjectReadyToCloseDto } from '../model';
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel existing project order (delete)
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoMessageDto} [gmDtoLibraryDtoMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCancelProjectOrder: async (contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoMessageDto?: GmDtoLibraryDtoMessageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectCancelProjectOrder', 'contractId', contractId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('projectCancelProjectOrder', 'orderId', orderId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectCancelProjectOrder', 'projectId', projectId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/orders/{orderId}/cancel`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create project order
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoProjectOrderDto} [gmDtoLibraryDtoProjectOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateProjectOrder: async (contractId: string, projectId: string, gmDtoLibraryDtoProjectOrderDto?: GmDtoLibraryDtoProjectOrderDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectCreateProjectOrder', 'contractId', contractId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectCreateProjectOrder', 'projectId', projectId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/orders`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoProjectOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create project order line  - product id must be set  - quantity must be set  - product price or order line unit price must set
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoProjectOrderLineDto} [gmDtoLibraryDtoProjectOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateProjectOrderLine: async (contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoProjectOrderLineDto?: GmDtoLibraryDtoProjectOrderLineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectCreateProjectOrderLine', 'contractId', contractId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('projectCreateProjectOrderLine', 'orderId', orderId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectCreateProjectOrderLine', 'projectId', projectId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/orders/{orderId}/orderLines`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoProjectOrderLineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete project order line  - remove order line from order
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {string} orderId 
         * @param {string} orderLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectOrderLine: async (contractId: string, projectId: string, orderId: string, orderLineId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectDeleteProjectOrderLine', 'contractId', contractId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectDeleteProjectOrderLine', 'projectId', projectId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('projectDeleteProjectOrderLine', 'orderId', orderId)
            // verify required parameter 'orderLineId' is not null or undefined
            assertParamExists('projectDeleteProjectOrderLine', 'orderLineId', orderLineId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/orders/{orderId}/orderLines/{orderLineId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"orderLineId"}}`, encodeURIComponent(String(orderLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Fulfill project
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectFulfillProject: async (contractId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectFulfillProject', 'contractId', contractId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectFulfillProject', 'projectId', projectId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/fulfill`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Finalize project order
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoMessageDto} [gmDtoLibraryDtoMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectFulfillProjectOrder: async (contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoMessageDto?: GmDtoLibraryDtoMessageDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectFulfillProjectOrder', 'contractId', contractId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('projectFulfillProjectOrder', 'orderId', orderId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectFulfillProjectOrder', 'projectId', projectId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/orders/{orderId}/fulfill`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoMessageDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of invoice receivers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetInvoiceReceivers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/projects/invoice-receivers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProducts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/projects/products`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project order
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectOrder: async (contractId: string, orderId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectGetProjectOrder', 'contractId', contractId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('projectGetProjectOrder', 'orderId', orderId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectGetProjectOrder', 'projectId', projectId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/orders/{orderId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project order line
         * @param {string} contractId 
         * @param {string} orderLineId 
         * @param {string} projectId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectOrderLine: async (contractId: string, orderLineId: string, projectId: string, orderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectGetProjectOrderLine', 'contractId', contractId)
            // verify required parameter 'orderLineId' is not null or undefined
            assertParamExists('projectGetProjectOrderLine', 'orderLineId', orderLineId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectGetProjectOrderLine', 'projectId', projectId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('projectGetProjectOrderLine', 'orderId', orderId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/orders/{orderId}/orderLines/{orderLineId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"orderLineId"}}`, encodeURIComponent(String(orderLineId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project order lines
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectOrderLines: async (contractId: string, orderId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectGetProjectOrderLines', 'contractId', contractId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('projectGetProjectOrderLines', 'orderId', orderId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectGetProjectOrderLines', 'projectId', projectId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/orders/{orderId}/orderLines`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project orders list
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectOrders: async (contractId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectGetProjectOrders', 'contractId', contractId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectGetProjectOrders', 'projectId', projectId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/orders`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of orders that is not invoiced
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectOrdersNotInvoiced: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/projects/non-invoiced-orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger sending of email dunning final invoice
         * @param {string} forestOperationContractId 
         * @param {string} entrepreneurTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectEmailDunningFinalInvoice: async (forestOperationContractId: string, entrepreneurTeamId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('projectProjectEmailDunningFinalInvoice', 'forestOperationContractId', forestOperationContractId)
            // verify required parameter 'entrepreneurTeamId' is not null or undefined
            assertParamExists('projectProjectEmailDunningFinalInvoice', 'entrepreneurTeamId', entrepreneurTeamId)
            const localVarPath = `/v1/projects/{forestOperationContractId}/emailDunningFinalInvoice/{entrepreneurTeamId}`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)))
                .replace(`{${"entrepreneurTeamId"}}`, encodeURIComponent(String(entrepreneurTeamId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get list of projects ready to close
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectsReadyToClose: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/projects/ready2close`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reopen project
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectReopenProject: async (contractId: string, projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectReopenProject', 'contractId', contractId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectReopenProject', 'projectId', projectId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/reopen`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send project order email to economy
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoProjectOrderEmailDto} [gmDtoLibraryDtoProjectOrderEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectSblProjectOrderEmailToEconomy: async (forestOperationContractId: string, gmDtoLibraryDtoProjectOrderEmailDto?: GmDtoLibraryDtoProjectOrderEmailDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'forestOperationContractId' is not null or undefined
            assertParamExists('projectSblProjectOrderEmailToEconomy', 'forestOperationContractId', forestOperationContractId)
            const localVarPath = `/v1/projects/{forestOperationContractId}/email2Economi`
                .replace(`{${"forestOperationContractId"}}`, encodeURIComponent(String(forestOperationContractId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoProjectOrderEmailDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update project order
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {string} orderId 
         * @param {GmDtoLibraryDtoProjectOrderDto} [gmDtoLibraryDtoProjectOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectOrder: async (contractId: string, projectId: string, orderId: string, gmDtoLibraryDtoProjectOrderDto?: GmDtoLibraryDtoProjectOrderDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectUpdateProjectOrder', 'contractId', contractId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectUpdateProjectOrder', 'projectId', projectId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('projectUpdateProjectOrder', 'orderId', orderId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/orders/{orderId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoProjectOrderDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update project order line  - change quantity and/or price
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {string} orderLineId 
         * @param {GmDtoLibraryDtoProjectOrderLineDto} [gmDtoLibraryDtoProjectOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectOrderLine: async (contractId: string, orderId: string, projectId: string, orderLineId: string, gmDtoLibraryDtoProjectOrderLineDto?: GmDtoLibraryDtoProjectOrderLineDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('projectUpdateProjectOrderLine', 'contractId', contractId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('projectUpdateProjectOrderLine', 'orderId', orderId)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('projectUpdateProjectOrderLine', 'projectId', projectId)
            // verify required parameter 'orderLineId' is not null or undefined
            assertParamExists('projectUpdateProjectOrderLine', 'orderLineId', orderLineId)
            const localVarPath = `/v1/projects/{contractId}/{projectId}/orders/{orderId}/orderLines/{orderLineId}`
                .replace(`{${"contractId"}}`, encodeURIComponent(String(contractId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"orderLineId"}}`, encodeURIComponent(String(orderLineId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmDtoLibraryDtoProjectOrderLineDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cancel existing project order (delete)
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoMessageDto} [gmDtoLibraryDtoMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCancelProjectOrder(contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoMessageDto?: GmDtoLibraryDtoMessageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCancelProjectOrder(contractId, orderId, projectId, gmDtoLibraryDtoMessageDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectCancelProjectOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create project order
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoProjectOrderDto} [gmDtoLibraryDtoProjectOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCreateProjectOrder(contractId: string, projectId: string, gmDtoLibraryDtoProjectOrderDto?: GmDtoLibraryDtoProjectOrderDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoProjectOrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCreateProjectOrder(contractId, projectId, gmDtoLibraryDtoProjectOrderDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectCreateProjectOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Create project order line  - product id must be set  - quantity must be set  - product price or order line unit price must set
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoProjectOrderLineDto} [gmDtoLibraryDtoProjectOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCreateProjectOrderLine(contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoProjectOrderLineDto?: GmDtoLibraryDtoProjectOrderLineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoProjectOrderLineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCreateProjectOrderLine(contractId, orderId, projectId, gmDtoLibraryDtoProjectOrderLineDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectCreateProjectOrderLine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Delete project order line  - remove order line from order
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {string} orderId 
         * @param {string} orderLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteProjectOrderLine(contractId: string, projectId: string, orderId: string, orderLineId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoProjectOrderLineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteProjectOrderLine(contractId, projectId, orderId, orderLineId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectDeleteProjectOrderLine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Fulfill project
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectFulfillProject(contractId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectFulfillProject(contractId, projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectFulfillProject']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Finalize project order
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoMessageDto} [gmDtoLibraryDtoMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectFulfillProjectOrder(contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoMessageDto?: GmDtoLibraryDtoMessageDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectFulfillProjectOrder(contractId, orderId, projectId, gmDtoLibraryDtoMessageDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectFulfillProjectOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of invoice receivers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetInvoiceReceivers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoInvoiceReceiverDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetInvoiceReceivers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectGetInvoiceReceivers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProducts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoProductDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProducts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectGetProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get project order
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectOrder(contractId: string, orderId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoProjectOrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectOrder(contractId, orderId, projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectGetProjectOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get project order line
         * @param {string} contractId 
         * @param {string} orderLineId 
         * @param {string} projectId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectOrderLine(contractId: string, orderLineId: string, projectId: string, orderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoProjectOrderLineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectOrderLine(contractId, orderLineId, projectId, orderId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectGetProjectOrderLine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get project order lines
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectOrderLines(contractId: string, orderId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoProjectOrderLineDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectOrderLines(contractId, orderId, projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectGetProjectOrderLines']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get project orders list
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectOrders(contractId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoProjectOrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectOrders(contractId, projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectGetProjectOrders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of orders that is not invoiced
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectOrdersNotInvoiced(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoOrderDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectOrdersNotInvoiced(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectOrdersNotInvoiced']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Trigger sending of email dunning final invoice
         * @param {string} forestOperationContractId 
         * @param {string} entrepreneurTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectProjectEmailDunningFinalInvoice(forestOperationContractId: string, entrepreneurTeamId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectProjectEmailDunningFinalInvoice(forestOperationContractId, entrepreneurTeamId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectProjectEmailDunningFinalInvoice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Get list of projects ready to close
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectProjectsReadyToClose(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GmDtoLibraryDtoProjectReadyToCloseDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectProjectsReadyToClose(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectProjectsReadyToClose']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Reopen project
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectReopenProject(contractId: string, projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectReopenProject(contractId, projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectReopenProject']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Send project order email to economy
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoProjectOrderEmailDto} [gmDtoLibraryDtoProjectOrderEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectSblProjectOrderEmailToEconomy(forestOperationContractId: string, gmDtoLibraryDtoProjectOrderEmailDto?: GmDtoLibraryDtoProjectOrderEmailDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectSblProjectOrderEmailToEconomy(forestOperationContractId, gmDtoLibraryDtoProjectOrderEmailDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectSblProjectOrderEmailToEconomy']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update project order
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {string} orderId 
         * @param {GmDtoLibraryDtoProjectOrderDto} [gmDtoLibraryDtoProjectOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectOrder(contractId: string, projectId: string, orderId: string, gmDtoLibraryDtoProjectOrderDto?: GmDtoLibraryDtoProjectOrderDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoProjectOrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectOrder(contractId, projectId, orderId, gmDtoLibraryDtoProjectOrderDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectUpdateProjectOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Update project order line  - change quantity and/or price
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {string} orderLineId 
         * @param {GmDtoLibraryDtoProjectOrderLineDto} [gmDtoLibraryDtoProjectOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectOrderLine(contractId: string, orderId: string, projectId: string, orderLineId: string, gmDtoLibraryDtoProjectOrderLineDto?: GmDtoLibraryDtoProjectOrderLineDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmDtoLibraryDtoProjectOrderLineDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectOrderLine(contractId, orderId, projectId, orderLineId, gmDtoLibraryDtoProjectOrderLineDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ProjectApi.projectUpdateProjectOrderLine']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @summary Cancel existing project order (delete)
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoMessageDto} [gmDtoLibraryDtoMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCancelProjectOrder(contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoMessageDto?: GmDtoLibraryDtoMessageDto, options?: any): AxiosPromise<void> {
            return localVarFp.projectCancelProjectOrder(contractId, orderId, projectId, gmDtoLibraryDtoMessageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create project order
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoProjectOrderDto} [gmDtoLibraryDtoProjectOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateProjectOrder(contractId: string, projectId: string, gmDtoLibraryDtoProjectOrderDto?: GmDtoLibraryDtoProjectOrderDto, options?: any): AxiosPromise<GmDtoLibraryDtoProjectOrderDto> {
            return localVarFp.projectCreateProjectOrder(contractId, projectId, gmDtoLibraryDtoProjectOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create project order line  - product id must be set  - quantity must be set  - product price or order line unit price must set
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoProjectOrderLineDto} [gmDtoLibraryDtoProjectOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateProjectOrderLine(contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoProjectOrderLineDto?: GmDtoLibraryDtoProjectOrderLineDto, options?: any): AxiosPromise<GmDtoLibraryDtoProjectOrderLineDto> {
            return localVarFp.projectCreateProjectOrderLine(contractId, orderId, projectId, gmDtoLibraryDtoProjectOrderLineDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete project order line  - remove order line from order
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {string} orderId 
         * @param {string} orderLineId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectOrderLine(contractId: string, projectId: string, orderId: string, orderLineId: string, options?: any): AxiosPromise<GmDtoLibraryDtoProjectOrderLineDto> {
            return localVarFp.projectDeleteProjectOrderLine(contractId, projectId, orderId, orderLineId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Fulfill project
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectFulfillProject(contractId: string, projectId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.projectFulfillProject(contractId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Finalize project order
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {GmDtoLibraryDtoMessageDto} [gmDtoLibraryDtoMessageDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectFulfillProjectOrder(contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoMessageDto?: GmDtoLibraryDtoMessageDto, options?: any): AxiosPromise<void> {
            return localVarFp.projectFulfillProjectOrder(contractId, orderId, projectId, gmDtoLibraryDtoMessageDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of invoice receivers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetInvoiceReceivers(options?: any): AxiosPromise<Array<GmDtoLibraryDtoInvoiceReceiverDto>> {
            return localVarFp.projectGetInvoiceReceivers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProducts(options?: any): AxiosPromise<Array<GmDtoLibraryDtoProductDto>> {
            return localVarFp.projectGetProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project order
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectOrder(contractId: string, orderId: string, projectId: string, options?: any): AxiosPromise<GmDtoLibraryDtoProjectOrderDto> {
            return localVarFp.projectGetProjectOrder(contractId, orderId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project order line
         * @param {string} contractId 
         * @param {string} orderLineId 
         * @param {string} projectId 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectOrderLine(contractId: string, orderLineId: string, projectId: string, orderId: string, options?: any): AxiosPromise<GmDtoLibraryDtoProjectOrderLineDto> {
            return localVarFp.projectGetProjectOrderLine(contractId, orderLineId, projectId, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project order lines
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectOrderLines(contractId: string, orderId: string, projectId: string, options?: any): AxiosPromise<Array<GmDtoLibraryDtoProjectOrderLineDto>> {
            return localVarFp.projectGetProjectOrderLines(contractId, orderId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project orders list
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectOrders(contractId: string, projectId: string, options?: any): AxiosPromise<Array<GmDtoLibraryDtoProjectOrderDto>> {
            return localVarFp.projectGetProjectOrders(contractId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of orders that is not invoiced
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectOrdersNotInvoiced(options?: any): AxiosPromise<Array<GmDtoLibraryDtoOrderDto>> {
            return localVarFp.projectOrdersNotInvoiced(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger sending of email dunning final invoice
         * @param {string} forestOperationContractId 
         * @param {string} entrepreneurTeamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectEmailDunningFinalInvoice(forestOperationContractId: string, entrepreneurTeamId: string, options?: any): AxiosPromise<string> {
            return localVarFp.projectProjectEmailDunningFinalInvoice(forestOperationContractId, entrepreneurTeamId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get list of projects ready to close
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectProjectsReadyToClose(options?: any): AxiosPromise<Array<GmDtoLibraryDtoProjectReadyToCloseDto>> {
            return localVarFp.projectProjectsReadyToClose(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reopen project
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectReopenProject(contractId: string, projectId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.projectReopenProject(contractId, projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send project order email to economy
         * @param {string} forestOperationContractId 
         * @param {GmDtoLibraryDtoProjectOrderEmailDto} [gmDtoLibraryDtoProjectOrderEmailDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectSblProjectOrderEmailToEconomy(forestOperationContractId: string, gmDtoLibraryDtoProjectOrderEmailDto?: GmDtoLibraryDtoProjectOrderEmailDto, options?: any): AxiosPromise<string> {
            return localVarFp.projectSblProjectOrderEmailToEconomy(forestOperationContractId, gmDtoLibraryDtoProjectOrderEmailDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update project order
         * @param {string} contractId 
         * @param {string} projectId 
         * @param {string} orderId 
         * @param {GmDtoLibraryDtoProjectOrderDto} [gmDtoLibraryDtoProjectOrderDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectOrder(contractId: string, projectId: string, orderId: string, gmDtoLibraryDtoProjectOrderDto?: GmDtoLibraryDtoProjectOrderDto, options?: any): AxiosPromise<GmDtoLibraryDtoProjectOrderDto> {
            return localVarFp.projectUpdateProjectOrder(contractId, projectId, orderId, gmDtoLibraryDtoProjectOrderDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update project order line  - change quantity and/or price
         * @param {string} contractId 
         * @param {string} orderId 
         * @param {string} projectId 
         * @param {string} orderLineId 
         * @param {GmDtoLibraryDtoProjectOrderLineDto} [gmDtoLibraryDtoProjectOrderLineDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectOrderLine(contractId: string, orderId: string, projectId: string, orderLineId: string, gmDtoLibraryDtoProjectOrderLineDto?: GmDtoLibraryDtoProjectOrderLineDto, options?: any): AxiosPromise<GmDtoLibraryDtoProjectOrderLineDto> {
            return localVarFp.projectUpdateProjectOrderLine(contractId, orderId, projectId, orderLineId, gmDtoLibraryDtoProjectOrderLineDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @summary Cancel existing project order (delete)
     * @param {string} contractId 
     * @param {string} orderId 
     * @param {string} projectId 
     * @param {GmDtoLibraryDtoMessageDto} [gmDtoLibraryDtoMessageDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectCancelProjectOrder(contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoMessageDto?: GmDtoLibraryDtoMessageDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectCancelProjectOrder(contractId, orderId, projectId, gmDtoLibraryDtoMessageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create project order
     * @param {string} contractId 
     * @param {string} projectId 
     * @param {GmDtoLibraryDtoProjectOrderDto} [gmDtoLibraryDtoProjectOrderDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectCreateProjectOrder(contractId: string, projectId: string, gmDtoLibraryDtoProjectOrderDto?: GmDtoLibraryDtoProjectOrderDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectCreateProjectOrder(contractId, projectId, gmDtoLibraryDtoProjectOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create project order line  - product id must be set  - quantity must be set  - product price or order line unit price must set
     * @param {string} contractId 
     * @param {string} orderId 
     * @param {string} projectId 
     * @param {GmDtoLibraryDtoProjectOrderLineDto} [gmDtoLibraryDtoProjectOrderLineDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectCreateProjectOrderLine(contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoProjectOrderLineDto?: GmDtoLibraryDtoProjectOrderLineDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectCreateProjectOrderLine(contractId, orderId, projectId, gmDtoLibraryDtoProjectOrderLineDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete project order line  - remove order line from order
     * @param {string} contractId 
     * @param {string} projectId 
     * @param {string} orderId 
     * @param {string} orderLineId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteProjectOrderLine(contractId: string, projectId: string, orderId: string, orderLineId: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteProjectOrderLine(contractId, projectId, orderId, orderLineId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Fulfill project
     * @param {string} contractId 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectFulfillProject(contractId: string, projectId: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectFulfillProject(contractId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Finalize project order
     * @param {string} contractId 
     * @param {string} orderId 
     * @param {string} projectId 
     * @param {GmDtoLibraryDtoMessageDto} [gmDtoLibraryDtoMessageDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectFulfillProjectOrder(contractId: string, orderId: string, projectId: string, gmDtoLibraryDtoMessageDto?: GmDtoLibraryDtoMessageDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectFulfillProjectOrder(contractId, orderId, projectId, gmDtoLibraryDtoMessageDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of invoice receivers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetInvoiceReceivers(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetInvoiceReceivers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProducts(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project order
     * @param {string} contractId 
     * @param {string} orderId 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectOrder(contractId: string, orderId: string, projectId: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectOrder(contractId, orderId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project order line
     * @param {string} contractId 
     * @param {string} orderLineId 
     * @param {string} projectId 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectOrderLine(contractId: string, orderLineId: string, projectId: string, orderId: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectOrderLine(contractId, orderLineId, projectId, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project order lines
     * @param {string} contractId 
     * @param {string} orderId 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectOrderLines(contractId: string, orderId: string, projectId: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectOrderLines(contractId, orderId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project orders list
     * @param {string} contractId 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectOrders(contractId: string, projectId: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectOrders(contractId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of orders that is not invoiced
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectOrdersNotInvoiced(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectOrdersNotInvoiced(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger sending of email dunning final invoice
     * @param {string} forestOperationContractId 
     * @param {string} entrepreneurTeamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectProjectEmailDunningFinalInvoice(forestOperationContractId: string, entrepreneurTeamId: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectProjectEmailDunningFinalInvoice(forestOperationContractId, entrepreneurTeamId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get list of projects ready to close
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectProjectsReadyToClose(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectProjectsReadyToClose(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reopen project
     * @param {string} contractId 
     * @param {string} projectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectReopenProject(contractId: string, projectId: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectReopenProject(contractId, projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send project order email to economy
     * @param {string} forestOperationContractId 
     * @param {GmDtoLibraryDtoProjectOrderEmailDto} [gmDtoLibraryDtoProjectOrderEmailDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectSblProjectOrderEmailToEconomy(forestOperationContractId: string, gmDtoLibraryDtoProjectOrderEmailDto?: GmDtoLibraryDtoProjectOrderEmailDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectSblProjectOrderEmailToEconomy(forestOperationContractId, gmDtoLibraryDtoProjectOrderEmailDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update project order
     * @param {string} contractId 
     * @param {string} projectId 
     * @param {string} orderId 
     * @param {GmDtoLibraryDtoProjectOrderDto} [gmDtoLibraryDtoProjectOrderDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectOrder(contractId: string, projectId: string, orderId: string, gmDtoLibraryDtoProjectOrderDto?: GmDtoLibraryDtoProjectOrderDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectOrder(contractId, projectId, orderId, gmDtoLibraryDtoProjectOrderDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update project order line  - change quantity and/or price
     * @param {string} contractId 
     * @param {string} orderId 
     * @param {string} projectId 
     * @param {string} orderLineId 
     * @param {GmDtoLibraryDtoProjectOrderLineDto} [gmDtoLibraryDtoProjectOrderLineDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectOrderLine(contractId: string, orderId: string, projectId: string, orderLineId: string, gmDtoLibraryDtoProjectOrderLineDto?: GmDtoLibraryDtoProjectOrderLineDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectOrderLine(contractId, orderId, projectId, orderLineId, gmDtoLibraryDtoProjectOrderLineDto, options).then((request) => request(this.axios, this.basePath));
    }
}

